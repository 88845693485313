(function($) {
  $.extend($.summernote.lang, {
    'ta-IN': {
      font: {
        bold: 'தடித்த',
        italic: 'சாய்வு',
        underline: 'அடிக்கோடு',
        clear: 'நீக்கு',
        height: 'வரி  உயரம்',
        name: 'எழுத்துரு பெயர்',
        strikethrough: 'குறுக்குக் கோடு',
        size: 'எழுத்துரு அளவு',
        superscript: 'மேல் ஒட்டு',
        subscript: 'கீழ் ஒட்டு',
      },
      image: {
        image: 'படம்',
        insert: 'படத்தை செருகு',
        resizeFull: 'முழு அளவை',
        resizeHalf: 'அரை அளவை',
        resizeQuarter: 'கால் அளவை',
        floatLeft: 'இடப்பக்கமாக வை',
        floatRight: 'வலப்பக்கமாக வை',
        floatNone: 'இயல்புநிலையில் வை',
        shapeRounded: 'வட்டமான வடிவம்',
        shapeCircle: 'வட்ட வடிவம்',
        shapeThumbnail: 'சிறு வடிவம்',
        shapeNone: 'வடிவத்தை நீக்கு',
        dragImageHere: 'படத்தை இங்கே இழுத்துவை',
        dropImage: 'படத்தை விடு',
        selectFromFiles: 'கோப்புகளை தேர்வு செய்',
        maximumFileSize: 'அதிகபட்ச கோப்பு அளவு',
        maximumFileSizeError: 'கோப்பு அதிகபட்ச அளவை மீறிவிட்டது',
        url: 'இணையதள முகவரி',
        remove: 'படத்தை நீக்கு',
        original: 'Original',
      },
      video: {
        video: 'காணொளி',
        videoLink: 'காணொளி இணைப்பு',
        insert: 'காணொளியை செருகு',
        url: 'இணையதள முகவரி',
        providers: '(YouTube, Vimeo, Vine, Instagram, DailyMotion or Youku)',
      },
      link: {
        link: 'இணைப்பு',
        insert: 'இணைப்பை செருகு',
        unlink: 'இணைப்பை நீக்கு',
        edit: 'இணைப்பை தொகு',
        textToDisplay: 'காட்சி வாசகம்',
        url: 'இணையதள முகவரி',
        openInNewWindow: 'புதிய சாளரத்தில் திறக்க',
      },
      table: {
        table: 'அட்டவணை',
        addRowAbove: 'Add row above',
        addRowBelow: 'Add row below',
        addColLeft: 'Add column left',
        addColRight: 'Add column right',
        delRow: 'Delete row',
        delCol: 'Delete column',
        delTable: 'Delete table',
      },
      hr: {
        insert: 'கிடைமட்ட கோடு',
      },
      style: {
        style: 'தொகுப்பு',
        p: 'பத்தி',
        blockquote: 'மேற்கோள்',
        pre: 'குறியீடு',
        h1: 'தலைப்பு 1',
        h2: 'தலைப்பு 2',
        h3: 'தலைப்பு 3',
        h4: 'தலைப்பு 4',
        h5: 'தலைப்பு 5',
        h6: 'தலைப்பு 6',
      },
      lists: {
        unordered: 'வரிசையிடாத',
        ordered: 'வரிசையிட்ட',
      },
      options: {
        help: 'உதவி',
        fullscreen: 'முழுத்திரை',
        codeview: 'நிரலாக்க காட்சி',
      },
      paragraph: {
        paragraph: 'பத்தி',
        outdent: 'வெளித்தள்ளு',
        indent: 'உள்ளே தள்ளு',
        left: 'இடது சீரமைப்பு',
        center: 'நடு சீரமைப்பு',
        right: 'வலது சீரமைப்பு',
        justify: 'இருபுற சீரமைப்பு',
      },
      color: {
        recent: 'அண்மை நிறம்',
        more: 'மேலும்',
        background: 'பின்புல நிறம்',
        foreground: 'முன்புற நிறம்',
        transparent: 'தெளிமையான',
        setTransparent: 'தெளிமையாக்கு',
        reset: 'மீட்டமைக்க',
        resetToDefault: 'இயல்புநிலைக்கு மீட்டமை',
      },
      shortcut: {
        shortcuts: 'குறுக்குவழி',
        close: 'மூடு',
        textFormatting: 'எழுத்து வடிவமைப்பு',
        action: 'செயல்படுத்து',
        paragraphFormatting: 'பத்தி வடிவமைப்பு',
        documentStyle: 'ஆவண பாணி',
        extraKeys: 'Extra keys',
      },
      help: {
        'insertParagraph': 'Insert Paragraph',
        'undo': 'Undoes the last command',
        'redo': 'Redoes the last command',
        'tab': 'Tab',
        'untab': 'Untab',
        'bold': 'Set a bold style',
        'italic': 'Set a italic style',
        'underline': 'Set a underline style',
        'strikethrough': 'Set a strikethrough style',
        'removeFormat': 'Clean a style',
        'justifyLeft': 'Set left align',
        'justifyCenter': 'Set center align',
        'justifyRight': 'Set right align',
        'justifyFull': 'Set full align',
        'insertUnorderedList': 'Toggle unordered list',
        'insertOrderedList': 'Toggle ordered list',
        'outdent': 'Outdent on current paragraph',
        'indent': 'Indent on current paragraph',
        'formatPara': 'Change current block\'s format as a paragraph(P tag)',
        'formatH1': 'Change current block\'s format as H1',
        'formatH2': 'Change current block\'s format as H2',
        'formatH3': 'Change current block\'s format as H3',
        'formatH4': 'Change current block\'s format as H4',
        'formatH5': 'Change current block\'s format as H5',
        'formatH6': 'Change current block\'s format as H6',
        'insertHorizontalRule': 'Insert horizontal rule',
        'linkDialog.show': 'Show Link Dialog',
      },
      history: {
        undo: 'மீளமை',
        redo: 'மீண்டும்',
      },
      specialChar: {
        specialChar: 'SPECIAL CHARACTERS',
        select: 'Select Special characters',
      },
    },
  });
})(jQuery);
